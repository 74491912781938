const val: { options: any, getQuery: (withStats: boolean) => string} = {
    options: null,
    getQuery: (withStats) => (`
        parent {
            id,
            firstName,
            lastName,
            ${withStats ? `stats {
                metric,
                value,
            },` : ''}
            address,
            secondPhoneNumber,
            secondAddress,
            joinDate,
            county,
            country,
            town,
            postCode,
            email,
            phoneNumber,
        }`
    ),
};

export default val;
