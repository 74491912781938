import { EventType, EnrolleeType } from '@/api/graphQL/graphNodes/types';
import dateManager from '@/utils/time';
import mockedDateManager from '@/utils/mocked-date-manager';
import env from '@/environment';
import { defaultBankDetails } from '@/modules/Profile/defaults';
import { defaultShippingAddress } from '../Event/defaults';

export const defaultEnrollee: () => EnrolleeType = () => ({
    address: '',
    secondAddress: null,
    postCode: null,
    country: null,
    county: null,
    town: null,
    birthDate: '',
    secondPhoneNumber: null,
    ambassadorLegsCount: 0,
    customers: [],
    directDownline: [],
    email: '',
    firstName: '',
    lastName: '',
    stats: [],
    id: '',
    joinDate: '',
    kitPurchaseDate: '',
    qualifiedBranchesCount: 0,
    leaderLegsCount: 0,
    leaderLegs: [],
    paidForKit: false,
    parentId: '',
    phoneNumber: '',
    rankId: '',
    risingStars: [],
    additionalCommission: '0.00',
    ctb: '0.00',
    leadershipBonus: '0.00',
    totalSales: '0.00',
    agreedToTermsAndConditions: false,
    ambassadorsCountsByActivityLevel: [],
    risingStarsCount: 0,
    bankDetails: defaultBankDetails(),
    shadow: true,
    hidden: false,
});

export const defaultEvent: () => EventType = () => ({
    address: '',
    ambassador: defaultEnrollee(),
    eventDateFrom: '',
    eventDateTo: '',
    campaignDateFrom: '',
    campaignDateTo: '',
    id: '',
    hostess: defaultEnrollee(),
    title: '',
    description: '',
    allowGuestsToInvite: false,
    invitations: [],
    eventOrders: [],
    confirmed: false,
    inviteLink: '',
    canceled: false,
    closed: false,
    type: '',
    totalSales: 0,
    shippingAddress: defaultShippingAddress(),
});

export const defaultCurrentDay: () => string = () => (
    env.VUE_APP_MOCK_GRAPHQL === 'true'
        ? mockedDateManager.getCurrentDate(dateManager.getDateFormat())
        : dateManager.getCurrentDate(dateManager.getDateFormat())
);
