import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { LeaderLegsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetLeaderLegsByIdQueryParamsType, GetLeaderLegsByIdQueryResultType } from '@/api/graphQL/graphNodes/GetLeaderLegsByIdQuery';
import StatsManager from '@/utils/stats-manager';
import LeaderLegsRepository from './services/leaderLegsRepository';
import requirementsLegModule, { Leg } from './requirementLeaderLegsLeg';

@Module({
    namespaced: true, dynamic: true, store, name: 'requirementLeaderLegs',
})
@AutoMutations
export class RequirementLeaderLegs extends VuexModule {
    private legs: LeaderLegsType[] = [];

    private loaded: boolean = false;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private activeLegDetailsDialog: string = '';

    private expandedLeaderLegs: string[] = [];

    private legsCount: number = 0;

    private limit: number = 20;

    private offset: number = 0;

    private activeSponsorDetailsModal: string = '';

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setData(val: LeaderLegsType[]) {
        this.legs = [...val];
    }

    @Mutation
    public addData(val: LeaderLegsType[]) {
        this.legs = [...this.legs, ...val];
    }

    @Mutation
    public setActiveLegDetailsDialog(val: string) {
        this.activeLegDetailsDialog = val;
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedLeaderLegs.find((item) => item === val)) {
            const index = this.expandedLeaderLegs.findIndex((item) => item === val);
            this.expandedLeaderLegs.splice(index, 1);
        } else {
            this.expandedLeaderLegs.push(val);
        }
    }

    @Mutation
    clearExpandedLeaderLegs() {
        this.expandedLeaderLegs = [];
    }

    @Mutation
    setLegsCount(val: number) {
        this.legsCount = val;
    }

    @Mutation
    setOffset(val: number) {
        this.offset = val;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Action()
    public clearLeaderLegsLegsDialogData() {
        this.setActiveLegDetailsDialog('');
        this.setData([]);
        requirementsLegModule.setElements([]);
        this.setOffset(0);
        this.setLoaded(false);
        this.clearExpandedLeaderLegs();
    }

    @Action()
    public async getLeaderLegs(data: {id: string, loadPage?: boolean, offset?: number }) {
        const { id, loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: GetLeaderLegsByIdQueryParamsType = {
                limit: this.limit,
                offset,
                id,
            };

            const result: GetLeaderLegsByIdQueryResultType = await LeaderLegsRepository
                .getLeaderLegsById(params);

            const {
                leaderLegs, leaderLegsCount,
            } = result.enrollee;

            leaderLegs
                .sort((a, b) => {
                    const pvA = StatsManager.resolveStat(a.stats, StatsManager.statKeyConstant.pv);
                    const pvB = StatsManager.resolveStat(b.stats, StatsManager.statKeyConstant.pv);

                    return Number(pvA) < Number(pvB) ? 1 : -1;
                });

            requirementsLegModule.setElements([]);
            leaderLegs.forEach((star) => {
                const leg = new Leg();
                leg.id = star.id;
                leg.data = { ...leg.data, ...star };

                requirementsLegModule.addElement(leg);
                requirementsLegModule.loadData(star.id);
            });

            this.setLegsCount(leaderLegsCount);

            this.setData(leaderLegs);
            this.setOffset(offset);

            if (!loadPage) {
                this.setLoaded(true);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }
}

export default getModule(RequirementLeaderLegs);
