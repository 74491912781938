import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { LeaderLegsType } from '@/api/graphQL/graphNodes/types';
import {
    GetLeaderLegsResultType, GetLeaderLegsSearchResultType, LeaderLegsParamsType,
    LeaderLegsSearchParamsType,
} from '@/api/graphQL/graphNodes/GetLeaderLegsQuery';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import StatsManager from '@/utils/stats-manager';
import LeaderLegsRepository from './services/leaderLegsRepository';
import legModule, { Leg } from './leg';
import legsRenderingList from './legsRenderingList';
import { LeaderLegsDisplayItemType } from './types';

@Module({
    namespaced: true, dynamic: true, store, name: 'leaderLegs',
})
@AutoMutations
export class LeaderLegs extends VuexModule {
    private loaded: boolean = false;

    private loading: boolean = false;

    private activeLegDetailsDialog: string = '';

    private expandedLeaderLegs: string[] = [];

    private loadingInBackground: boolean = false;

    private legsCount: number = 0;

    private limit: number = 10;

    private offset: number = 0;

    private activeSponsorDetailsModal: string = '';

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private searchedLegsOptions: LeaderLegsType[] = [];

    private minSearchLength: number = 3;

    private displaySearchResults: boolean = false;

    private qualifiedLeaderBranchIds: string[] = [];

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setActiveLegDetailsDialog(val: string) {
        this.activeLegDetailsDialog = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedLeaderLegs.find((item) => item === val)) {
            const index = this.expandedLeaderLegs.findIndex((item) => item === val);
            this.expandedLeaderLegs.splice(index, 1);
        } else {
            this.expandedLeaderLegs.push(val);
        }
    }

    @Mutation
    clearExpandedList() {
        this.expandedLeaderLegs = [];
    }

    @Mutation
    setLegsCount(val: number) {
        this.legsCount = val;
    }

    @Mutation
    setOffset(val: number = 0) {
        this.offset = val;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    setSearchedOptionsLegs(val: LeaderLegsType[]) {
        this.searchedLegsOptions = val;
    }

    @Mutation
    setSearchQuery(val: string) {
        this.searchQuery = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    setQualifiedLeaderBranchIds(val: string[]) {
        this.qualifiedLeaderBranchIds = [...val];
    }

    @Action()
    public async getLeaderLegs(data: {loadPage?: boolean, offset?: number }) {
        const { loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: LeaderLegsParamsType = {
                limit: this.limit,
                offset,
            };

            const result: GetLeaderLegsResultType = await LeaderLegsRepository
                .getLeaderLegs(params) as GetLeaderLegsResultType;

            const {
                leaderLegs, leaderLegsCount, qualifiedLeaderBranchIds,
            } = result.profile;

            this.setQualifiedLeaderBranchIds(qualifiedLeaderBranchIds);

            leaderLegs
                .sort((a, b) => {
                    const pvA = StatsManager.resolveStat(a.stats, StatsManager.statKeyConstant.pv);
                    const pvB = StatsManager.resolveStat(b.stats, StatsManager.statKeyConstant.pv);

                    return Number(pvA) < Number(pvB) ? 1 : -1;
                });

            legModule.setElements([]);
            leaderLegs.forEach((star) => {
                this.handleLegCreate(star);
            });

            this.setLegsCount(leaderLegsCount);

            const legsData: LeaderLegsDisplayItemType[] = leaderLegs
                .map((leg) => ({ id: leg.id, parentId: '-1', level: 0 }));

            legsRenderingList.setData(legsData);
            this.setOffset(offset);

            if (!loadPage) {
                this.setLoaded(true);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoadingInBackground(false);
            this.setLoading(false);
        }
    }

    @Action()
    async search(data: { query?: string|null, selectedSearch?: boolean }
    = { query: null, selectedSearch: false }) {
        const { query, selectedSearch } = data;

        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: LeaderLegsSearchParamsType = {
                query: query || query === '' ? query : this.searchQuery,
            };

            const result: GetLeaderLegsSearchResultType = await LeaderLegsRepository
                .searchLeaderLegs(params) as GetLeaderLegsSearchResultType;

            const { searchLeaderLegs } = result.profile;

            if (selectedSearch) {
                searchLeaderLegs.forEach((star) => {
                    if (!legModule.collection[star.id] && selectedSearch) {
                        this.handleLegCreate(star);
                    }
                });

                const searchedLegsData: LeaderLegsDisplayItemType[] = searchLeaderLegs
                    .map((leg) => ({ id: leg.id, parentId: '-1', level: 0 }));

                legsRenderingList.setSearchedLegs(searchedLegsData);
            } else {
                this.setSearchedOptionsLegs(searchLeaderLegs);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }

    @Action()
    handleLegCreate(star: LeaderLegsType) {
        const leg = new Leg();
        leg.level = 0;
        leg.id = star.id;
        leg.data = { ...leg.data, ...star };

        legModule.addElement(leg);
    }
}

export default getModule(LeaderLegs);
