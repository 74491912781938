import store from '@/store';
import { CollectionModule, CollectionVuexModule } from '@/utils/vuex-module-mutators';
import { getModule, Mutation } from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { GetAddressOptionsParamsType } from '@/api/rest/Resources/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { AddressSelectOptionsType } from '@/modules/AddressSelect/types';
import { defaultAddressOption } from '../../defaults';
import AddressOptionsRepository from '../../AddressSelect/services/addressOptionsRepository';
import authLocalStorageManager from '../../Auth/services/authLocalStorageManager';

export class Option {
    id: string = '';

    data: AddressSelectOptionsType = defaultAddressOption();

    childOptionIds: string[] = [];

    containerOpen: boolean = false;

    searchPhrase: string = '';

    loading: boolean = false;

    loaded: boolean = false;
}

@CollectionModule({
    namespaced: true, dynamic: true, store, name: 'eventShippingAddressSelectOptions', item: Option,
})
class EventShippingAddressSelectOptions extends CollectionVuexModule<Option> {
    @Mutation
    public setData(params: { id: string, val: AddressSelectOptionsType}) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].data = { ...this.collection[id].data, ...val };
        }
    }

    @Mutation
    public addChildOption(params: { id: string, val: string}) {
        const { id, val } = params;
        if (this.collection[id].childOptionIds!.indexOf(val) === -1) {
            this.collection[id].childOptionIds!.push(val);
        }
    }

    @Mutation
    public removeChildOption(params: { id: string, val: string}) {
        const { id, val } = params;
        this.collection[id].childOptionIds = this
            .collection[id].childOptionIds!.filter((item) => item !== val);
    }

    @Mutation
    public setLoading(params: {id: string, val: boolean}) {
        const { id, val } = params;

        this.collection[id].loading = val;
    }

    @Mutation
    public setLoaded(params: {id: string, val: boolean}) {
        const { id, val } = params;

        this.collection[id].loaded = val;
    }

    @Mutation
    public setContainerOpen(params: {id: string, val: boolean}) {
        const { id, val } = params;

        this.collection[id].containerOpen = val;
    }

    @Mutation
    public setSearchPhrase(params: {id: string, val: string}) {
        const { id, val } = params;

        this.collection[id].searchPhrase = val;
    }

    @Action()
    public async loadOptions(data: { id: string, region: string }) {
        const { id, region } = data;
        try {
            this.setLoading({ id, val: true });

            const params: GetAddressOptionsParamsType = {
                token: authLocalStorageManager.getAuthToken() || '',
                text: this.collection[id].searchPhrase!,
                region,
                container: id,
            };

            const res = await AddressOptionsRepository.getAddressOptions(params);

            res.Items.forEach((item) => {
                if (!this.collection[item.Id] && item.Type === 'Address') {
                    const option = new Option();
                    option.data = {
                        description: item.Description,
                        highlight: item.Highlight,
                        text: item.Text,
                        type: item.Type,
                    };

                    const thisElement = this.collection[id];
                    option.searchPhrase = thisElement.searchPhrase!;
                    option.id = item.Id;

                    this.addElement(option);

                    this.addChildOption({ id, val: item.Id });
                    this.setLoaded({ id, val: true });
                }
            });
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading({ id, val: false });
        }
    }

    @Action()
    public clearOption(id) {
        const thisElement = this.collection[id];
        if (thisElement.childOptionIds!.length > 0) {
            thisElement.childOptionIds!.forEach((element) => {
                this.clearOption(element);
                this.removeChildOption({ id, val: element });
                this.removeElement(element);
            });
        }

        this.removeElement(id);
    }
}

export default getModule(EventShippingAddressSelectOptions);
