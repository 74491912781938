
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CreateEventType } from '@/modules/Event/Events/types';
import { DATE_PICKER_TYPES } from '@/components/datePicker/constants';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import settings from '@/modules/Settings';
import { Get } from '@/utils/vuex-module-mutators';

@Component({
    components: {
        PropertyInfoDisplay,
    },
})
export default class EventDetailsForm extends Vue {
    @Prop() private value!: CreateEventType;

    @Prop() private formErrors!: any;

    @Prop() private descriptionPlaceholder!: any;

    @Prop({ default: false }) eventEditView!: boolean;

    @Prop() private disabledAll!: boolean;

    @Get(settings) eventGuestsInviteOptionEnabled!: boolean;

    get dateTypes() {
        return DATE_PICKER_TYPES;
    }

    handleValue(value: string, name: string) {
        const res = { key: name, val: value };

        this.$emit('input', res);
    }

    get descriptionEditorOptions() {
        return {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                ],
            },
            placeholder: this.descriptionPlaceholder,
        };
    }
}
