import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import {
    AWARD_KEY, BUSINESS_DEVELOPMENT_TYPES, MILESTONES_TOOLTIP_TYPES, MILESTONE_KEY,
} from '../constants';

class WildcardManager {
    wildcardLabelResolver(type: string) {
        if (type === MILESTONE_KEY.consistentLeaderWildcard) {
            return 'Consistent Wildcard';
        }

        if (type === MILESTONE_KEY.newLeaderWildcard) {
            return 'New Leader Wildcard Entries';
        }

        if (type === MILESTONE_KEY.activityWildcard) {
            return 'Activity Wildcard';
        }

        if (type === MILESTONE_KEY.travelSelling) {
            return 'Sales Wildcard';
        }

        if (type === MILESTONE_KEY.travelRecruiting) {
            return 'Recruiting Wildcard';
        }

        if (type === MILESTONE_KEY.travelPromoting) {
            return 'Promoting Wildcard';
        }

        if (type === MILESTONE_KEY.travelLeadership) {
            return 'Leadership Wildcard';
        }

        if (type === MILESTONE_KEY.fakeTravel) {
            return 'Overall';
        }

        return 'New Leader Wildcard Entries';
    }

    resolveLabelType(milestones: MilestoneType[], sortByAchievable: boolean = false) {
        const gold = milestones.find((item) => item.category === 'gold');
        const silver = milestones.find((item) => item.category === 'silver');
        const bronze = milestones.find((item) => item.category === 'bronze');
        let res = '';

        switch (true) {
        case !!gold && (sortByAchievable ? !gold.unachievable : gold.achieved):
            res = BUSINESS_DEVELOPMENT_TYPES.gold;
            break;
        case !!silver && (sortByAchievable ? !silver.unachievable : silver.achieved):
            res = BUSINESS_DEVELOPMENT_TYPES.silver;
            break;
        case !!bronze && (sortByAchievable ? !bronze.unachievable : bronze.achieved):
            res = BUSINESS_DEVELOPMENT_TYPES.bronze;
            break;
        default:
            res = 'unachievable';
            break;
        }

        return res;
    }

    businessDevelopmentLabelResolver(
        milestones: MilestoneType[],
        sortByAchievable: boolean = false,
    ) {
        const type = this.resolveLabelType(milestones, sortByAchievable);
        let label = '';

        switch (type) {
        case BUSINESS_DEVELOPMENT_TYPES.gold:
            label = 'Gold member';
            break;
        case BUSINESS_DEVELOPMENT_TYPES.silver:
            label = 'Silver member';

            break;
        case BUSINESS_DEVELOPMENT_TYPES.bronze:
            label = 'Bronze member';

            break;

        default:
            label = 'N/A';
            break;
        }
        return label;
    }

    quickStartLabelResolver(type: string) {
        let res = {
            short: 'N/A',
            full: 'Not Available',
        };

        switch (type) {
        case 'kit_boost':
            res = {
                short: 'KB',
                full: 'Kit Boost',
            };
            break;
        case 'kit_refund':
            res = {
                short: 'KR',
                full: 'Kit Refund',
            };
            break;

        case 'business_boost':
            res = {
                short: 'BB',
                full: 'Business Boost',
            };
            break;

        case 'double_promotion':
            res = {
                short: 'DPB',
                full: 'Double Promotion',
            };
            break;

        case 'sales_starter':
            res = {
                short: 'SS',
                full: 'Sales Starter',
            };
            break;

        case 'sales_builder':
            res = {
                short: 'SB',
                full: 'Sales Builder',
            };
            break;

        case 'sales_champion':
            res = {
                short: 'SC',
                full: 'Sales Champion',
            };
            break;

        case 'team_starter':
            res = {
                short: 'TS',
                full: 'Team Starter',
            };
            break;

        case 'team_builder':
            res = {
                short: 'TB',
                full: 'Team Builder',
            };
            break;

        case 'team_champion':
            res = {
                short: 'TC',
                full: 'Team Champion',
            };
            break;

        default:
            break;
        }

        return res;
    }

    pathwayTooltipResolver(key) {
        let res = '';
        switch (key) {
        case MILESTONES_TOOLTIP_TYPES.travel:
            res = 'Travel Incentive';
            break;
        case MILESTONES_TOOLTIP_TYPES.business:
            res = 'Business Development Programme';
            break;
        case MILESTONES_TOOLTIP_TYPES.quick:
            res = 'QuickStart Milestones';
            break;
        case MILESTONES_TOOLTIP_TYPES.wildcard:
            res = 'Wildcard Progress';
            break;

        default:
            break;
        }

        return res;
    }

    directionResolver(position: number, previousPosition: number|null) {
        let direction = 'stay';

        if (previousPosition) {
            if (Number(previousPosition) > Number(position)) {
                direction = 'up';
            } else if (Number(previousPosition) < Number(position)) {
                direction = 'down';
            }
        }

        return direction;
    }

    resolveDirectionIcon(position: number, previousPosition: number|null, baseUrl: string) {
        const direction = this.directionResolver(position, previousPosition);

        let icon = 'remove.svg';

        switch (direction) {
        case 'down':
            icon = 'moving_down.svg';
            break;
        case 'up':
            icon = 'moving_up.svg';
            break;
        default:
            break;
        }

        return `${baseUrl}img/icons/awards/${icon}`;
    }

    resolveWildcardRedirect(type: string): boolean {
        return [MILESTONE_KEY.travelSelling, MILESTONE_KEY.travelRecruiting,
            MILESTONE_KEY.travelLeadership, MILESTONE_KEY.fakeTravel].includes(type);
    }

    resolveAwardType(type: string) {
        let res: string = '';

        switch (type) {
        case MILESTONE_KEY.travelSelling:
            res = AWARD_KEY.topTravelSeller;
            break;
        case MILESTONE_KEY.travelRecruiting:
            res = AWARD_KEY.topTravelRecruiter;
            break;
        case MILESTONE_KEY.travelLeadership:
            res = AWARD_KEY.topTravelLeader;
            break;
        case MILESTONE_KEY.fakeTravel:
            res = AWARD_KEY.topTravelOverall;
            break;
        default:
            break;
        }
        return res;
    }

    resolveWildcardSize(type: string): number {
        let res: number = 0;
        switch (type) {
        case MILESTONE_KEY.travelLeadership:
            res = 5;
            break;
        case MILESTONE_KEY.travelRecruiting:
            res = 25;
            break;
        case MILESTONE_KEY.travelSelling:
            res = 50;
            break;
        case MILESTONE_KEY.fakeTravel:
            res = 15;
            break;

        default:
            break;
        }

        return res;
    }
}

export default new WildcardManager();
