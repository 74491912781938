import { StatsType } from '@/api/graphQL/graphNodes/types';

export default class StatsManager {
    static readonly statKeyConstant = {
        pv: 'pv',
        gv: 'gv',
        uv: 'uv',
        ov: 'ov',
        recruits: 'recruits',
        recruitsIn: 'recruits_in',
        level: 'level',
        leaders: 'leaders',
        lessThanThreeMonthsInBusiness: 'less_than_three_months_in_business',
        exemptPs: 'exempt_ps',
        maternityLeaveDate: 'maternity_leave_date',
        maternityReturnDate: 'maternity_return_date',
        inGrace: 'in_grace',
        activeOnFile: 'active_on_file',
        monthsSincePaidAsTitle: 'months_since_paid_as_title',
        achievedDoublePromotionMilestone: 'achieved_double_promotion_milestone',
        holdRank: 'hold_rank',
        qualifiedGroupBranchLegs: 'qualified_group_branch_legs',
        qualifiedBranches: 'qualified_branches',
        qualifiedGroupLegs: 'qualified_group_legs',
        qualifiedFrontlineLeaders: 'qualified_frontline_leaders',
        qualifiedGroupBranchActiveOnFileLegs: 'qualified_group_branch_active_on_file_legs',
        activeOnFileDirectAmbassadors: 'active_on_file_direct_ambassadors',
        ambassadorKit: 'ambassadorKit',
        qualifiedLeaderBranches: 'qualified_leader_branches',
        qualifiedPersonalLegs: 'qualified_personal_legs',
    }

    public static resolveStat(stats: StatsType[], key: string): string {
        const stat = stats.find((item) => item.metric === key);

        if (stat) {
            return stat.value;
        }
        return '';
    }
}
