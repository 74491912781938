
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { ErrorType } from '@plumtreesystems/utils';
import { HostessFormType } from '@/modules/Event/types';
import { EVENT_HOST_TYPE } from '@/modules/Event/constants';
import eventNewHostessAddressSelect from '@/modules/Event/EventNewHostessAddressSelect';
import eventNewHostessAddressSelectOption from '@/modules/Event/EventNewHostessAddressSelect/addressOption';
import BaseAddressSelect from '@/projectComponents/addressSelect/base.vue';
import profile from '@/modules/Profile';
import { SelectOptionsType } from '@/components/select/types';

@Component({
    components: {
        BaseAddressSelect,
    },
})
export default class HostessForm extends Vue {
    @Get(eventNewHostessAddressSelect) optionsIds!: string[];

    @Sync(eventNewHostessAddressSelect) private searchWord!: string;

    @Get(eventNewHostessAddressSelect) private loading!: boolean;

    @Get(eventNewHostessAddressSelect) private resultSize!: number;

    @Get(profile, 'data.region') private region!: string;

    @Prop() private eventHostType!: number;

    @Prop({ required: true }) private hostess!: HostessFormType;

    @Prop({ required: true }) private formErrors!: ErrorType;

    handleHostessUpdate(val, name) {
        const data = { ...this.hostess };
        data[name] = val;
        this.$emit('input', data);
    }

    get disabled(): boolean {
        return this.eventHostType === EVENT_HOST_TYPE.known;
    }

    get options(): SelectOptionsType[] {
        return this.optionsIds.map((item) => {
            const { data, id } = eventNewHostessAddressSelectOption.collection[item];
            const description = data!.description !== '' ? `, ${data!.description}` : '';

            return {
                name: `${data!.text}${description}`,
                value: id!,
            };
        });
    }

    setLabel(val: SelectOptionsType) {
        eventNewHostessAddressSelect.setAddress(String(val.value));
        eventNewHostessAddressSelect.setSearchWord(val.name);
    }

    loadOptions() {
        eventNewHostessAddressSelect.findAddresses({
            region: this.region,
        });
    }
}
