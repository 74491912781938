
import addressSelect from '@/modules/AddressSelect';
import addressSelectOption from '@/modules/AddressSelect/addressOption';
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import Base from './base.vue';

@Component({
    components: {
        Base,
    },
})
export default class AddressSelect extends Vue {
    @Get(addressSelect) optionsIds!: string[];

    @Sync(addressSelect) searchWord!: string;

    @Get(addressSelect) loading!: boolean;

    @Get(addressSelect) resultSize!: number;

    @Prop({ default: true }) private required!: boolean;

    @Prop({ default: null }) private region!: string;

    @Prop({ default: 'Address' }) private label!: string;

    @Prop() icon!: string;

    @Prop() error!: string;

    @Prop({ default: false }) private disabled!: boolean;

    get options(): SelectOptionsType[] {
        return this.optionsIds.map((item) => {
            const { data, id } = addressSelectOption.collection[item];
            const description = data!.description !== '' ? `, ${data!.description}` : '';

            return {
                name: `${data!.text}${description}`,
                value: id!,
            };
        });
    }

    setLabel(val: SelectOptionsType) {
        addressSelect.setAddress(String(val.value));
        addressSelect.setSearchWord(val.name);
    }

    loadOptions() {
        const { token } = this.$route.query;
        if (token) {
            addressSelect.findAddresses({ token: token.toString(), region: this.region });
        } else {
            addressSelect.findAddresses({ region: this.region });
        }
    }
}
