
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import dashboardStats from '@/modules/Dashboard/stats';
import StatsManager from '@/utils/stats-manager';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class PersonalRecruits extends Vue {
    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    get personalRecruitsCount():number {
        return Number(StatsManager
            .resolveStat(this.stats, StatsManager.statKeyConstant.qualifiedPersonalLegs));
    }

    get realPersonalRecruitsCount(): number {
        const foundItem = this.stats
            .find((item) => item.metric === this.personalRecruitsCountRequirement!.metric);

        if (foundItem) {
            return Number(foundItem.value);
        }
        return this.personalRecruitsCount;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.personalRecruits;
    }

    get metricOld(): string {
        return RankRequirementManager.rankRequirementMetricConstants.personalRecruitsOld;
    }

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get personalRecruitsCountRequirement(): RankRequirementType | null {
        const requirement = this.requirements
            .find((item) => item.metric === this.metric || item.metric === this.metricOld);

        return requirement || null;
    }

    get progressValue(): number|null {
        const x = this.realPersonalRecruitsCount * 100;
        const personalRecruitsCount = this.personalRecruitsCountRequirement;

        if (!personalRecruitsCount) {
            return null;
        }

        if (Number(personalRecruitsCount.value) === 0) {
            return 0;
        }

        return x / Number(personalRecruitsCount.value);
    }

    get displayData(): boolean {
        const personalRecruitsCount = this.personalRecruitsCountRequirement;

        if (!personalRecruitsCount) {
            return false;
        }

        return true;
    }
}
