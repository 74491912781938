const descriptionResolver: (type: string) => string = (type) => {
    switch (type) {
    case 'kit_boost_uk':
        return 'Requires £360 Personal Sales in 21 days period since joining.';
    case 'kit_boost_non_uk':
        return 'Requires £360 Personal Sales in 28 period days since joining.';
    case 'kit_refund_uk':
        return 'Requires £720 Personal Sales in 42 days period since joining.';
    case 'kit_refund_non_uk':
        return 'Requires 720 Personal Sales in 49 days period since joining.';
    case 'business_boost_uk':
        return 'Requires 2 New Recruits in 63 days period since joining.';
    case 'business_boost_non_uk':
        return 'Requires 2 New Recruits in 70 days period since joining.';
    case 'double_promotion':
        return 'Requires Promoting to Team Leader Title in 3 calendar months since joining.';
    case 'sales_starter_uk':
        return 'Requires £150 Personal Sales in one order within 2 weeks since joining.';
    case 'sales_starter_non_uk':
        return 'Requires £150 Personal Sales in one order within 3 weeks since joining.';
    case 'sales_builder_uk':
        return 'Requires £500 Personal Sales within 6 weeks since joining.';
    case 'sales_builder_non_uk':
        return 'Requires £500 Personal Sales within 7 weeks since joining.';
    case 'sales_champion_uk':
        return 'Requires £1000 Personal Sales within 10 weeks since joining.';
    case 'sales_champion_non_uk':
        return 'Requires £1000 Personal Sales within 11 weeks since joining.';
    case 'team_starter':
        return 'Requires 1 New Recruit within 6 months since joining.';
    case 'team_builder':
        return 'Requires 1 New Recruit within 4 weeks since achieving Team Starter.';
    case 'team_champion':
        return 'Requires Promoting to Team Leader Title within 8 weeks since achieving Team Builder.';
    default:
        return '';
    }
};

export default descriptionResolver;
