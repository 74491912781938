// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { RisingStarsType } from './types';

export default class GetRisingStarsByIdQuery extends
    AbstractQueryResource<GetRisingStarsByIdResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!, $limit: Int!, $offset: Int!) {
            enrollee(id: $id) {
                risingStars(limit: $limit, offset: $offset) {
                    id,
                    stats: {
                        metric,
                        value,
                    },
                    firstName,
                    lastName,
                    rank {
                        id,
                        label,
                        power,
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                    aOFStatus,
                },
                risingStarsCount,
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getRisingStarsListById';
    }
}

export type GetRisingStarsByIdResultType = {
    enrollee: {
        risingStars: RisingStarsType[];
        risingStarsCount: number;
    }
}

export type GetRisingStarsByIdParamsType = {
    id: string;
    limit: number;
    offset: number;
}
