
import {
    Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { BusinessDetailsType } from '@/api/graphQL/graphNodes/types';
import BaseAddressSelect from '@/projectComponents/addressSelect/base.vue';
import profileBusinessAddressSelect from '@/modules/ProfileBusinessAddressSelect';
import profileAddressSelectOption from '@/modules/ProfileBusinessAddressSelect/addressOption';
import { SelectOptionsType } from '@/components/select/types';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import resolveRegionRequirement from '@/modules/Countries/services/resolveRegionRequirement';

@Component({
    components: {
        BaseAddressSelect,
        PropertyInfoDisplay,
    },
})
export default class ProfileBusinessDetails extends Vue {
    @Get(profile) private businessDetails!: BusinessDetailsType;

    @Get(profile) private formErrors!: ErrorType;

    @Get(profile) private disableBusinessDetails!: boolean;

    @Get(profileBusinessAddressSelect) optionsIds!: string[];

    @Sync(profileBusinessAddressSelect) private searchWord!: string;

    @Get(profileBusinessAddressSelect) private loading!: boolean;

    @Get(profileBusinessAddressSelect) private resultSize!: number;

    @Prop() private isGb!: boolean;

    @Prop() private region!: string;

    get options(): SelectOptionsType[] {
        return this.optionsIds.map((item) => {
            const { data, id } = profileAddressSelectOption.collection[item];
            const description = data!.description !== '' ? `, ${data!.description}` : '';

            return {
                name: `${data!.text}${description}`,
                value: id!,
            };
        });
    }

    get regionRequirements(): string[] {
        return resolveRegionRequirement(this.region);
    }

    get typePersonal(): boolean {
        return false;
    }

    get typeBusiness(): boolean {
        return true;
    }

    get ukLabel() {
        return 'Business account:';
    }

    get otherLabel() {
        return 'Are you registered as a company?';
    }

    handleBusinessValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.businessDetails);
        profile.removeFormError(`businessDetails.${key}`);

        if (key === 'businessName') {
            profile.removeFormError('businessDetails.businessAddressLookup');
        }
        profile.setBusinessDetails(res);
    }

    @Watch('searchWord')
    clearAddressLookupErrors() {
        profile.removeFormError('businessDetails.businessName');
        profile.removeFormError('businessDetails.businessAddressLookup');
    }

    setLabel(val: SelectOptionsType) {
        profileBusinessAddressSelect.setAddress(String(val.value));
        profileBusinessAddressSelect.setSearchWord(val.name);
    }

    loadOptions() {
        profileBusinessAddressSelect.findAddresses({ region: this.region });
    }

    handleBusinessType(val: string) {
        profile.clearFormErrors();
        profile.setBusinessAccount(val === 'true');
    }
}
