
import { Vue, Component } from 'vue-property-decorator';
import FunctionalityManager from '@/utils/functionality-manager';
import BusinessDevelopment from './BusinessDevelopment/index.vue';
import QuickStart from './QuickStart/index.vue';
import TravelIncentive from './TravelIncentive/index.vue';
import WildcardProgress from './WildcardProgress/index.vue';
import Club250 from './Club250/index.vue';
import NewClub250 from './Club250/newLayout.vue';

@Component({
    components: {
        BusinessDevelopment,
        QuickStart,
        TravelIncentive,
        WildcardProgress,
        Club250,
        NewClub250,
    },
})
export default class Pathway extends Vue {
    get displayClub250() {
        return FunctionalityManager.shouldDisplay('club250');
    }

    get displayNewClub250() {
        return FunctionalityManager.shouldDisplay('newClub250');
    }
}
