
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { SelectOptionsType } from '@/components/select/types';
import register from '@/modules/Register';
import countries from '@/modules/Countries';
import UploadDialogContent from '@/projectComponents/uploadDialog/index.vue';
import Camera from '@/projectComponents/camera/index.vue';
import { RegisterAmbassadorType } from '@/api/graphQL/graphNodes/types';
import FormErrorTooltip from '@/projectComponents/formErrorTooltip/index.vue';
import FileViewer from '@/projectComponents/fileViewer/index.vue';
import RegistrationSteps from '@/projectComponents/registrationSteps/index.vue';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import { CountriesType } from '@/modules/Event/types';
import registerBusinessAddressSelect from '@/modules/RegisterBusinessAddressSelect';
import registerBusinessAddressOptions from '@/modules/RegisterBusinessAddressSelect/addressOption';
import BaseAddressSelect from '@/projectComponents/addressSelect/base.vue';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import resolveRegionRequirement from '@/modules/Countries/services/resolveRegionRequirement';

@Component({
    components: {
        UploadDialogContent,
        Camera,
        FormErrorTooltip,
        FileViewer,
        FormFieldTooltip,
        RegistrationSteps,
        BaseAddressSelect,
        PropertyInfoDisplay,
    },
})
export default class RegisterBusinessDetails extends Vue {
    @Get(register) private formData!: RegisterAmbassadorType;

    @Get(register) private formErrors!: ErrorType;

    @Get(register) private loading!: boolean;

    @Get(register) private loadingInBackground!: boolean;

    @Get(register) private businessType!: string;

    @Get(register) private disableBusinessDetails!: string;

    @Get(countries) private regions!: CountriesType[];

    @Sync(registerBusinessAddressSelect) private searchWord!: string;

    @Get(registerBusinessAddressSelect) optionsIds!: string[];

    @Get(registerBusinessAddressSelect, 'loading') private businessAddressLoading!: boolean;

    @Get(registerBusinessAddressSelect) private resultSize!: number;

    get businessAddressOptions(): SelectOptionsType[] {
        return this.optionsIds.map((item) => {
            const { data, id } = registerBusinessAddressOptions.collection[item];
            const description = data!.description !== '' ? `, ${data!.description}` : '';

            return {
                name: `${data!.text}${description}`,
                value: id!,
            };
        });
    }

    get regionRequirements(): string[] {
        return resolveRegionRequirement(this.formData.region);
    }

    get typePersonal(): boolean {
        return false;
    }

    get typeBusiness(): boolean {
        return true;
    }

    get ukLabel() {
        return 'Business account:';
    }

    get otherLabel() {
        return 'Are you registered as a company?';
    }

    get isGb(): boolean {
        return this.formData.region === COUNTRY_SELECT_OPTIONS.gb;
    }

    loadOptions() {
        const { token } = this.$route.query;
        registerBusinessAddressSelect.findAddresses({
            token: token.toString(),
            region: this.formData.region,
        });
    }

    setLabel(val: SelectOptionsType) {
        registerBusinessAddressSelect.setAddress(String(val.value));
        registerBusinessAddressSelect.setSearchWord(val.name);
    }

    handleBusinessType(val: string) {
        register.clearFormErrors();
        register.setBusinessAccount(val === 'true');
    }

    handleBusinessValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.formData.businessDetails);
        register.removeFormError(`businessDetails.${key}`);

        if (key === 'businessName') {
            register.removeFormError('businessDetails.businessAddressLookup');
        }

        register.setBusinessDetails(res);
    }

    @Watch('searchWord')
    clearAddressLookupError() {
        register.removeFormError('businessDetails.businessName');
        register.removeFormError('businessDetails.businessAddressLookup');
    }
}
