import API from '@/api/graphQL';
import { ExploreDownlineParamsType, ExploreDownlineSearchParamsType } from '@/api/graphQL/graphNodes/GetDownlineQuery';
import { GetGenealogyEnrolleeParamsType } from '@/api/graphQL/graphNodes/GetGenealogyEnrolleeQuery';

export default class DownlineExplorerRepository {
    static getData(data: ExploreDownlineParamsType) {
        return API
            .getDownline()
            .query(data, {}, { type: '' });
    }

    static searchData(data: ExploreDownlineSearchParamsType) {
        return API
            .getDownline()
            .query(data, {}, { type: 'search' });
    }

    static getLeg(params: GetGenealogyEnrolleeParamsType) {
        return API
            .getGenealogyEnrollee()
            .query(params, {}, { withParent: false });
    }
}
