// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { DownlineExplorerType, DownlineBreadcrumbsType, DownlineExplorerSearchType } from './types';

export default class GetDownlineQuery extends
    AbstractQueryResource<GetDownlineResultType|GetDownlineSearchResultType> {
    protected getQuery(options): string {
        const { type = '' } = options;
        return `query ${this.getName()}($id: String!, ${type === '' ? '$limit: Int!, $offset: Int!'
            : '$query: String!'}, $aofFilter: String) {
            exploreEnrollee(id: $id) {
                enrollee {
                    id,
                    parentId,
                    stats {
                        metric,
                        value,
                    },
                    email,
                    address,
                    secondPhoneNumber,
                    secondAddress,
                    county,
                    country,
                    town,
                    postCode,
                    phoneNumber,
                    firstName,
                    lastName,
                    joinDate,
                    rank {
                        id,
                        label,
                        power,
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                    ${type === '' ? 'directDownline(limit: $limit, offset: $offset, aofFilter: $aofFilter)' : 'searchDirectDownline(query: $query, aofFilter: $aofFilter)'} {
                        id,
                        email,
                        phoneNumber,
                        secondPhoneNumber,
                        secondAddress,
                        county,
                        country,
                        town,
                        postCode,
                        address,
                        firstName,
                        lastName,
                        rank {
                            label,
                            power,
                        },
                        payRank {
                            label,
                            power,
                        },
                        stats {
                            metric,
                            value,
                        },
                        directDownlineCount(aofFilter: $aofFilter),
                        aOFStatus,
                    },
                    directDownlineCount(aofFilter: $aofFilter),
                },
                ${type === '' ? `breadcrumbs {
                    id,
                },` : ''}
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getDownline';
    }
}

export type GetDownlineResultType = {
    exploreEnrollee: {
        enrollee: DownlineExplorerType;
        breadcrumbs: DownlineBreadcrumbsType[];
    }
}
export type GetDownlineSearchResultType = {
    exploreEnrollee: {
        enrollee: DownlineExplorerSearchType;
    }
}

export type ExploreDownlineParamsType = {
    id: string;
    limit: number;
    offset: number;
    aofFilter: string|null;
}

export type ExploreDownlineSearchParamsType = {
    id: string;
    query: string;
    aofFilter: string|null;
}
